define('modules/faqs/accordion/js/base.js',["app/module","app/config","jquery","app/util","templates","swiper.jquery.min","jquery.livequery"], function(module,config,$,util,templates) {
    return new module({
        name: 'faqs.accordion',
        selectors: '.faqWrapSingle',
        remoteData: [(config.urls.faqs)],
        
        config: {
            
        },

        routes: [{
            route: 'faqs',
            extra: true
        }],

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            $('#faqAccordion').collapse();
            $('#faqAccordion .panel-group').removeClass('collapsed in');
            this.attachEventHandlers();
        },

        onRender: function() {},

		methods: {
			renderTemplates: function (data) {
				$(this.getSelectors()).append(templates.faqsaccordion({
					records: data.sort(function(a, b) {
                        return a.id - b.id;
                    })
				}));
            },

            attachEventHandlers: function(data){
                this.$container.find('.faqAnchor.collapsed').on('click', function() {
                    var $self = $(this),
                        parent = $self.closest('.faqPanel');

                    if (parent.hasClass('opened')) {

                    } else {
                        var previouslyOpenedQuestion = $('.faqWrapSingle').find('.faqPanel.opened');

                        if (previouslyOpenedQuestion) {
                            previouslyOpenedQuestion.removeClass('opened');
                            previouslyOpenedQuestion.find('.faqIcon').removeClass('openedIcon');
                            previouslyOpenedQuestion.find('.faqPanelCollapse').slideUp(300).removeClass('in');
                        };

                        $self.find('.faqIcon').addClass('openedIcon');
                        $self.next('.faqPanelCollapse').slideDown(300).addClass('in');
                        $self.closest('.faqPanel').addClass('opened');
                    }
                });

                this.$container.find('.faqIcon').on('click', function(e) {
                    var $self = $(this);

                    if ($self.hasClass('openedIcon')) {
                        e.stopPropagation();
                        e.preventDefault();
                        var parent = $(this).closest('.faqPanel');

                        parent.removeClass('opened');
                        parent.find('.faqIcon').removeClass('openedIcon');
                        parent.find('.faqPanelCollapse').slideUp(300).removeClass('in');
                    }
                });
            }
		}
	});
});

