define('modules/navbar/compact/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "waypoints", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'navbar',
        selectors: '.navbarWrap',
        remoteData: [],

        routes: [{
            route: '.*',
            fn: function (hash) {
                // TODO - We need to sweep all the navbar classes and standarize this
                $('.navbar').removeClass('shrink');
                $(this.getSelectors()).removeClass('hidden');

                this.updateNavbarThemeClass();
            },
            extra: true
        }],

        config: {
            hiddenNavBar: true,
            stickySideNavBar: false,
            animatedLogo: true,
            logodelay: false,
            socialExpand: false,
            showOnHover: false,
            headerFooterNavBar: false,
            stickyShrinkHeader: false
        },

        initialize: function () {
            this.$container = $(this.getSelectors());
            this.blackPageHashes =  ["", "home", "404"];

            // Apply config
            if ($(this.getSelectors()).attr('data-hiddennav')) {
                this.getConfig().hiddenNavBar = true;
            }

            // Render the template
            $(this.getSelectors()).html(templates.navbar({
                showRegisterLogin: (typeof config.showRegisterLogin == 'undefined' ? true : config.showRegisterLogin),
                config: config // TODO - Filter this down to selected keys
            }));

            // Handling for activePageTitle text swaps
            // TODO - This will not longer properly fire against the :visible selector due to LQ upgrades
            // This is no longer utilized, when we need it again we should roll it into the module routes
            /*$(this.getSelectors()).livequery('.activePageTitle:visible', function () {
                $(this).text(util.getActivePageTitle());
            });*/

            // Set navbar animations depending on layout type / animation mode

            // nav social
            if (this.getConfig().socialExpand) {
                this.setSocialExpandHandlers();
            }

            if (this.getConfig().hiddenNavBar) {
                this.handleHiddenNavBar();
            } else if (this.getConfig().stickySideNavBar) {
                this.handleStickySideNavBar();
            } else if (this.getConfig().headerFooterNavBar) {
                this.handleHeaderFooterNavBar();
            }
            else if (this.getConfig().stickyShrinkHeader) {
                this.handleStickyShrinkHeader();
            }

            else {
                // Automatic detection of animation mode for navbar
                if ($(window).width() < 768) {
                    setTimeout($.proxy(this.basicAnimation, this), 1000);
                } else {
                    this.cardPanel();
                }
            }

            if (this.getConfig().logodelay) {
                var delayTime = (this.getConfig().logodelay);
                setTimeout($.proxy(function () {
                    $('.navbar-brand-holder').addClass('navbarBrand');
                }), delayTime);
            }

            if(this.getConfig().showOnHover) {
                $('body').on('mousemove',$.proxy(function(e){
                   e.preventDefault();
                   var scrollTop = $(window).scrollTop();
                   if(e.clientY < 200 && scrollTop > 800) {
                       $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                       if (!this.getConfig().animatedLogo) {
                           $('.navbarBrand').removeClass('nb-slidedown').addClass('nb-slideup');
                       }
                   }
                },this));
            }

            // Global body listeners
            $('body').on('navbartoggle', $.proxy(function (event, state) {
                if (state == true) {
                    $('.navbarWrap').removeClass('slideUpOut').find('.logo-animate-closed').removeClass('logo-animate-closed');
                } else {
                    $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut').find('.navbarBrand').addClass('logo-animate-closed');
                }
                if (this.getConfig().stickySideNavBar) {
                    $('.navbarMenuWrap').toggleClass('stickySideNavBar', !state);
                }
            }, this));

          this.attachEventHandlers();
        },

        methods: {

            attachEventHandlers: function() {
                var $body = $('body'),
                    shareToggle;

                // Detect and handle close button
                this.$container.on('click', '.jsMenuControl', $.proxy(function(e) {
                    e.preventDefault();

                    if ($body.hasClass('menuOpened')) {
                        shareToggle = false;
                    } else {
                        shareToggle = true;
                    };

                    // $('.socialShare').toggleClass('socialExpanded', shareToggle);
                    $body.toggleClass('menuOpened');

                    if ($(window).width() < 768) {
                        $body.addClass('locked');
                    };
                }, this));

                // this.socialEventHandler();

                this.scopedSocialShareHandler();
            },

            socialEventHandler: function() {
                $('.socialShare .txtWrap').on('click', function() {
                    var $self = $(this),
                        parent = $self.parent('.socialShare');

                    parent.addClass('socialExpanded');
                })
            },

            scopedSocialShareHandler: function() {

                $('body').on('click', '.socialLink', $.proxy(function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var element = e.target;

                    if (!$(element).hasClass('socialLink')) {
                        element = $(element).closest('.socialLink');
                    } else {
                        element = $(element);
                    }

                    this.onSocialClick(element);
                }, this));
            },

            onSocialClick: function (element) {

                var loc = location.href,
                    action = element.attr('data-action');

                if (action == 'twitter') {
                    var title = element.attr('data-title') || document.title;
                    window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'facebook') {
                    var t = element.attr('data-title') || document.title;
                    window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'youtube') {
                    var title = element.attr('data-title') || document.title;
                    window.location.assign("https://www.youtube.com/watch?v=" + title);
                } else if (action == 'google') {
                    window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                } else if (action == 'linkedin') {
                    var title = element.attr('data-title') || document.title;
                    var lishareText = element.attr('data-title') || document.title;
                    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(loc) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(lishareText), 'Linkedin Share', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            },

            basicAnimation: function () {
                $('.pageContainer .section:first-of-type').waypoint(function (direction) {
                    $('.navbar').toggleClass('shrink', (direction == 'down'));
                }, {
                    offset: -100,
                    context: '.pageContainer'
                });
                $('.pageContainer .section:last-of-type').waypoint(function (direction) {
                    $('.navbar').toggleClass('shrink', (direction == 'up'));
                }, {
                    offset: 100,
                    context: '.pageContainer'
                });
            },

            cardPanel: function () {
                // Navbar shrink state on panel snap events
                $('body').on('cardsnapfinish', function (event, panel) {
                    if ($(panel).parents('.pageContainer').hasClass('enableNavAni') || util.device.mobile()) {
                        $('.navbar')[($(panel).index() == 0 || $(panel).index() + 1 == $(panel).parent().children().length) ? 'removeClass' : 'addClass']('shrink');
                    }
                });
            },

            handleStickySideNavBar: function () {
                var wrap = $('.navbarWrap'),
                    menuWrap = $('.navbarMenuWrap'),
                    win = $(window),
                    body = $('body'),
                    scrollTop = win.scrollTop();

                win.on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', util.minScrollSpeed($.proxy(function () {
                    if (!wrap.hasClass('closed') && !body.hasClass('locked')) {
                        scrollTop = win.scrollTop();
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            //scroll down
                            $('.dashboard-menu-social-share').removeClass('social-expanded-on');
                            if (scrollTop > 500) {
                                wrap.removeClass('slideDownIn').addClass('slideUpOut');
                                setTimeout($.proxy(function () {
                                    wrap.addClass('topZIndex');
                                }), 1000);
                                menuWrap.removeClass('slide-from-right').addClass('stickySideNavBar');
                            }
                        } else {
                            //scroll up
                            if (scrollTop > 500) {
                                menuWrap.addClass('slide-from-right');
                            } else {
                                menuWrap.removeClass('slide-from-right');
                                setTimeout($.proxy(function () {
                                    menuWrap.removeClass('stickySideNavBar');
                                    wrap.removeClass('slideDownIn slideUpOut topZIndex');
                                }), 10);
                            }
                        }

                        //at top
                        if (scrollTop <= 1) {
                            menuWrap.removeClass('stickySideNavBar slide-from-right');
                            wrap.removeClass('slideDownIn slideUpOut topZIndex');
                        }
                        this.lastScrollTop = scrollTop;
                    }
                }, this)));
            },

            handleHiddenNavBar: function () {
                var wrap = $('.navbarWrap'),
                    brand = $('.navbarBrand'),
                    win = $(window),
                    body = $('body'),
                    scrollTop = win.scrollTop();

                win.on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(175, util.minScrollSpeed($.proxy(function () {
                    if (!wrap.hasClass('closed') && !body.hasClass('locked')) {
                        scrollTop = win.scrollTop();
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            wrap.removeClass('slideDownIn').addClass('slideUpOut');
                            if (this.getConfig().animatedLogo) {
                                if (!brand.hasClass('logo-animate-closed')) {
                                    brand.addClass('logo-animate-closed');
                                }
                            } else {
                                brand.removeClass('nb-slidedown').addClass('nb-slideup')
                            }
                        }
                        else {
                            if (!this.getConfig().showOnHover) {
                                wrap.removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                                if (!this.getConfig().animatedLogo) {
                                    brand.removeClass('nb-slidedown').addClass('nb-slideup');
                                }
                            }
                            if (scrollTop <= 100) {
                                if (this.getConfig().animatedLogo) {
                                    brand.removeClass('logo-animate-closed');
                                } else {
                                    brand.removeClass('nb-slideup').addClass('nb-slidedown');
                                }
                                wrap.removeClass('slideDownIn slideUpOut topZIndex').removeClass('mobile-transition');
                            }
                        }
                        this.lastScrollTop = scrollTop;
                    }
                }, this))));
            },

            handleHeaderFooterNavBar: function () {
                var wrap = $('.navbarWrap'),
                    brand = $('.navbarBrand'),
                    win = $(window),
                    body = $('body'),
                    scrollTop = win.scrollTop(),
                    windowHeight = win.height(),
                    docHeight = body.height();

                win.on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.proxy(function () {
                    if (!wrap.hasClass('closed') && !body.hasClass('locked')) {
                        scrollTop = win.scrollTop();
                        windowHeight = win.height();
                        docHeight = body.height();
                        this.updateNavbarThemeClass(scrollTop);
                        if ((scrollTop + windowHeight) > docHeight - 101) {
                            wrap.removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                        } else {
                            if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                                wrap.removeClass('slideDownIn').addClass('slideUpOut');
                                if (this.getConfig().animatedLogo) {
                                    if (!brand.hasClass('logo-animate-closed')) {
                                        brand.addClass('logo-animate-closed');
                                    }
                                }
                            } else {
                                wrap.removeClass('slideDownIn').addClass('slideUpOut');
                                if (scrollTop <= 300) {
                                    wrap.removeClass('slideUpOut mobile-transition').addClass('slideDownIn');
                                    if (this.getConfig().animatedLogo) {
                                        brand.removeClass('logo-animate-closed');
                                    }
                                }
                            }
                        }
                        this.lastScrollTop = scrollTop;
                    }
                }, this));

                setInterval($.proxy(function () {
                    if (win.scrollTop() <= 300) {
                        if (!body.hasClass('locked')) {
                            wrap.removeClass('slideUpOut mobile-transition').addClass('slideDownIn');
                            if (this.getConfig().animatedLogo) {
                                brand.removeClass('logo-animate-closed');
                            }
                        }
                    }
                }, this), 5000);
            },

            handleStickyShrinkHeader: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(175, util.minScrollSpeed($.proxy(function () {
                    // TODO test this, specific to navMap
                    var scrollTop = $(window).scrollTop();
                    var windowHeight = $(window).height();
                    var docHeight = $(document).height();

                    // simplest form
                    if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                        // scroll down
                        $('.navbarWrap').addClass('nav-shrink');
                    } else {
                        // scroll up
                        if (scrollTop <= 300) {
                            $('.navbarWrap').removeClass('nav-shrink');
                        }
                    }
                    this.lastScrollTop = scrollTop;
                }, this))));
            },

            setSocialExpandHandlers: function () {
                // TODO - Convert this size check into a window resize listener
                if ($(window).width() < 992) {
                    // TODO moving forward use this class social-expand-hover-icon
                    $('.dashboard-menu-social-share, .dashboard-menu-social-share .animated-heart-icon, .social-expand-hover-icon').click(function (e) {
                        if (e.target !== this)
                            return;
                        $('.dashboard-menu-social-share').addClass('social-expanded-on');
                    });
                    $('.mobile-close-social').click(function () {


                        $('.dashboard-menu-social-share').removeClass('social-expanded-on');
                    });
                }
                else {
                    $('.dashboard-menu-social-share, .social-expand-hover-icon').hover(
                        function () {
                            $(this).addClass('social-expanded-on');
                        }, function () {
                            $(this).removeClass('social-expanded-on');
                        }
                    );
                }
            },

            updateNavbarThemeClass: function(scrollTop){
                if(scrollTop == undefined){
                    scrollTop = $(window).scrollTop();
                }

                var navbarThemeClass = "white";
                var hash = window.location.hash.substring(1);

                if(scrollTop < 400){
                    $.each(this.blackPageHashes, function(index, pageHash){
                        if(pageHash == hash){
                            navbarThemeClass = "dark";
                            return false;
                        }
                    });
                }

                if (scrollTop > +($(window).height() * 2)) {
                    this.$container.addClass('withBackground');
                } else {
                    this.$container.removeClass('withBackground');
                }

                this.$container
                    .removeClass("dark white missing-page-nav")
                    .addClass(navbarThemeClass);
            }
        }
    });
});
