define('modules/navigation/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "app/redirect"], function (module, config, $, util, redirect) {
    return new module({
        name: 'navigationbasic',
        selectors: false,
        remoteData: [],
        extendable: true,

        lastRoute: null, // Null defaults to home

        routes: [{
            route: '.*',
            fn: function (hash) {
                $('body').removeClass('finalistsActive');
                return this.navigate(hash);
            },
            priority: 10
        }, {
            route: '.*',
            fn: 'trackRoutes',
            extra: true
        }, {
            route: 'assessment-tool',
            fn: function (hash) {
                var w = $('.typeform-widget'),
                    c = setInterval(function () {
                        if (w.find('iframe').length) {
                            clearInterval(c);
                            w.removeClass('typeform-widget-loading');
                        }
                    }, 300);
            },
            extra: true
        }, {
            route: 'finalists',
            fn: function () {
                $('body').addClass('finalistsActive');
            },
            extra: true
        }, {
            route: 'finalists/(.*)',
            fn: function () {
                $('body').addClass('finalistsActive');
            },
            extra: true
        }, {
            route: 'about/(.*)',
            fn: function (hash) {

                var scrollToSection = function (anchor) {
                    $('html, body').animate({
                        scrollTop: anchor.offset().top
                    }, 1500);
                };

                var aboutScrollTo = hash.split('/')[1];

                var elementOnPage = $('.aboutPage .about .' + aboutScrollTo);

                if (elementOnPage.length > 0) {
                    scrollToSection(elementOnPage);
                } else {
                    var insertListener = function (event) {
                        if (event.animationName == "elementInserted") {
                            // This is the debug for knowing our listener worked!
                            // event.target is the new node!
                            // console.warn("Another node has been inserted! ", event, event.target);

                            var scrollTarget = $(event.target);

                            if (scrollTarget.hasClass(aboutScrollTo)) {
                                $(document).ajaxStop(function () {
                                    scrollToSection(scrollTarget);
                                });
                            }
                        }
                    }

                    document.addEventListener("animationstart", insertListener, false); // standard + firefox
                    document.addEventListener("MSAnimationStart", insertListener, false); // IE
                    document.addEventListener("webkitAnimationStart", insertListener, false); // Chrome + Safari
                }
            },
            extra: true
        }],

        config: {
            smoothNavigate: true
        },


        initialize: function () {

        },

        methods: {

            navigate: function (hash) {
                //>>excludeStart("production",pragmas.production);
                console.debug('Basic navigation module trigger: ', hash);
                //>>excludeEnd("production");
                hash = this.setDefaultRoute(hash);
                if (hash) {

                    var pages = this.getNewPage(hash);

                    if (pages) {

                        if (pages.currentPage.is(pages.newPage)) {
                            // if currentPage and newPage are the same we don't do anything
                        } else {
                            // if currentPage and newPage are different, we are calling switchPages
                            this.switchPages(pages);
                        }
                    } else {
                        // This will open 404 page - handled in router.js
                        return false
                    }
                }
            },

            setDefaultRoute: function (hash) {
                // Set the default starting route
                if ((!hash && this.lastRoute === null) || (hash.length < 1)) {
                    return 'home';
                } else if (this.lastRoute == hash) {
                    return false;
                }
                return hash;
            },

            getNewPage: function (hash) {
                // Get our pages
                var $currentPage = $('.pageContainer[data-route="' + this.lastRoute + '"]'),
                    $newPage = $('.pageContainer[data-route="' + hash.split('/')[0] + '"]'),
                    pages = {};

                if (!$newPage || $newPage.length === 0) {
                    // this signals for 404 page - handled in navigate method and in router.js
                    return false;
                } else {
                    // If currentPage and newPage are different we want to trigger switchPages with the new pages variables
                    pages.currentPage = $currentPage;
                    pages.newPage = $newPage;
                    return pages;
                }
            },

            switchPages: function (pages) {
                //console.log('ccc', (pages.currentPage.selector.indexOf('finalists') < 0));
                if (pages.currentPage && pages.currentPage.length > 0 && pages.currentPage.attr('data-route-trackscroll') != undefined && (pages.currentPage.selector.indexOf('finalists') < 0)) {
                    pages.currentPage.attr('data-route-trackscroll', util.getScrollParent(pages.currentPage).scrollTop());
                }

                $('.pageContainer').removeClass('active').hide();
                pages.newPage.addClass('active').show().css('opacity', 1);

                // closing menu
                $('body').removeClass('menuOpened');

                $('body').removeClass('locked');

                if (pages.currentPage.selector.indexOf('finalists') < 0) {
                    if (typeof pages.newPage.attr('data-route-trackscroll') != 'undefined') {
                        util.getScrollParent(pages.newPage).scrollTop(pages.newPage.attr('data-route-trackscroll'));
                    } else {

                        if (this.smoothNavigate) {
                            util.scrollBodyToTop();
                        } else {
                            util.scrollBodyToTop(0);
                        }
                    }
                }
            },

            trackRoutes: function (hash) {
                this.lastRoute = hash;
            }

        }
    });
});
