define('modules/sidebar/classic/js/base.js',["app/module", "app/config","jquery","app/util","templates"], function(module, config,$,util,templates) {
    return new module ({
        name: 'sidebar',
        selectors: '.sidebar',
        remoteData: [config.urls.sidebar],

        routes: [{
            route: '.*',
            fn: function(hash) {
              var actualHash = hash.split('#')[0];
              this.addActiveLink(actualHash);
            },
            extra: true,
            delay: 150
        }],

        config: {
          socialShareInSidebar: true,
        },

        initialize: function (data, actualHash) {
          this.$container = $(this.getSelectors());
          // Load up the template
          this.$container.html(templates.sidebar({
              menuItems: $.grep(data,function(rec){
                  return rec.megamenu; // Only include items set to display in megamenu
              }),
              config: config
          }));
          
          this.attachEventHandlers();
          config.refresh();
        },

        methods: {

          attachEventHandlers: function(){
              // User clicks a link in the sidebar
              $('.sidebarItem').click(function(event) {
                  // If the sidebar has a tier1 menu...
                  if ( $('.sidebarItemNestedContainer', $(this) ).length ) {
                      // Disable href action
                      event.preventDefault();
                      // Toggle the item's 'selected' class
                      $(this).toggleClass('sidebarItemSelected');
                  }
              });

              // Sidebar inner (nested) menus
              $('.sidebarItemNestedContainer').click(function(event){
                  event.stopPropagation(); // Stop propagation on inner links
              });

              // $('.sidebar-inner-back').click(function(event) {
              //     setTimeout($.proxy(function(){
              //         $(this).parent('.sidebar-inner-list').addClass('inner-closed');
              //         $(this).parents('.sidebar-item-selected').height('auto');
              //     },this),1000);
              //     $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)-100+'%');
              //     event.stopPropagation();
              // });
              // $('.sidebar-inner-link').click(function(event) {
              //     $(this).children('.sidebar-inner-list').removeClass('inner-closed');
              //     $(this).parents('.sidebar-item').css('right',parseInt($(this).closest('.sidebar-item')[0].style.right || 0, 10)+100+'%');
              //     $(this).parents('.sidebar-item-selected').height($(this).children('.sidebar-inner-list').height());
              //     event.stopPropagation();
              // });

              this.$container.on('click', '.closeButton', function() {
                $('body').removeClass('menuOpened');

                $('body').removeClass('locked');
              });

              if (this.getConfig().socialShareInSidebar) {
                $('.socialShare .txtWrap').on('click', function () {
                  var $self = $(this),
                    socialContainer = $self.closest('.socialShare');
                    socialContainer.toggleClass('socialExpanded');
                });
  
                this.$container.on('click', '.socialShare .shareLink', $.proxy(function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                  var element = $(e.target);
                  if (!element.hasClass('shareLink')) {
                    element = $(element).closest('.shareLink');
                  };
                  this.onSocialClick(element);
                }, this));
              };
            },

            onSocialClick: function (element) {
              var loc = location.href,
                  action = element.attr('data-action');
  
              if (action == 'twitter') {
                  var title = element.attr('data-title') || document.title;
                  window.open('//twitter.com/intent/tweet?url=' + encodeURIComponent(loc) + '&text=' + encodeURIComponent(title), 'twitterwindow', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
              } else if (action == 'facebook') {
                  var t = element.attr('data-title') || document.title;
                  window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(loc) + '&t=' + encodeURIComponent(t), 'sharer', 'status=0,width=626,height=436, top=' + (100) + ', left=' + ($(window).width() / 2 + 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
              } else if (action == 'youtube') {
                  var title = element.attr('data-title') || document.title;
                  window.location.assign("https://www.youtube.com/watch?v=" + title);
              } else if (action == 'google') {
                  window.open('https://plus.google.com/share?url=' + encodeURIComponent(loc), 'Google Share', 'status=0,width=626,height=436, top=' + ($(window).height() / 2 - 225) + ', left=' + ($(window).width() / 2 - 313) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
              } else if (action == 'linkedin') {
                  var title = element.attr('data-title') || document.title;
                  var lishareText = element.attr('data-title') || document.title;
                  window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(loc) + '&title=' + encodeURIComponent(title) + '&summary=' + encodeURIComponent(lishareText), 'Linkedin Share', 'height=500, width=550, top=' + (100) + ', left=' + ($(window).width() / 2 + 275) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
              }
            },

            addActiveLink: function(actualHash) {
              // TO DO - test this further
              if (actualHash === '') {
                actualHash = 'home'
              };

              // the following checks for '/' in the hash - if it is subpage it adds the active class to the parent - e.g. 'news/1'
              hashStopper = '/';
              if (actualHash.indexOf(hashStopper) !== -1) {
                actualHash = actualHash.split('/')[0];
              };

              var oldLink = this.$container.find('.sidebarItemSelected');
              var newLink = this.$container.find('.sidebarItem[href="#' + actualHash + '"]');

              if (oldLink.length) {
                oldLink.removeClass('sidebarItemSelected');
              }

              newLink.addClass('sidebarItemSelected');

              if (newLink.hasClass('sidebarItemNested')) {
                newLink.closest('.sidebarItemNestedParent').addClass('sidebarItemSelected');
              }
            }
        }
    });
});
