define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgeBox\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeThumb layout-vertical-center\">\n        <img class=\"judgeThumbImg img-responsive\" src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n        <div class=\"judgeThumbTitle\">\n            <h5>"
    + alias4((helpers.formatName || (depth0 && depth0.formatName) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"formatName","hash":{},"data":data}))
    + "</h5>\n            <h6 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h6>\n            <span class=\"more\">Read More</span>\n        </div>\n    </div>\n</a> ";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"judgeGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n                <header class=\"judgeGroupHeading layout-vertical-center\">\n                    <h4 class=\"judgeHeadingTitle retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n                </header>\n\n                <div class=\"judgeGroupDescription retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n                    <p>"
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n                </div>\n\n                <div class=\"judgeGridContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</section>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        <div class=\"judgeQuoteContainer\"><q>"
    + container.escapeExpression(((helper = (helper = helpers.quote || (depth0 != null ? depth0.quote : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"quote","hash":{},"data":data}) : helper)))
    + "</q></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeInfoContainer border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"judgeInfoText col-xs-12\">\n                <div class=\"judgeInfoHead\">\n                    <div class=\"judgeInfoImg\"><img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\"></div>\n                    <div class=\"judgeInfoDescription\">\n                        <span class=\"judgeName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n                        <span class=\"judgeShortDescription\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"judgeLongDescription hidden-xs hidden-sm\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n        <div class=\"row\">\n            <div class=\"judgeInfoText col-sm-12 col-md-offset-5 col-md-6 visible-xs visible-sm\">\n                <div class=\"judgeLongDescription\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.quote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeDetailContainer container\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"judgeInfoGroup row\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n\n        <div class=\"col-md-12\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"judgeDetailClose\">\n            <i class=\"judgeDetailCloseInner\"></i>\n        </div>\n        <span class=\"judgeDetailNav prev\">\n          <span class=\"judgeDetailNavInner\"></span>\n        </span>\n        <span class=\"judgeDetailNav next\">\n          <span class=\"judgeDetailNavInner\"></span>\n        </span>\n        <div class=\"judgeDetailCounter\">\n            <div class=\"currentCount\">1</div>\n            <span class=\"divider\">of</span>\n            <div class=\"totalCount\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("newslistingentry", this["JST"]["newslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<span class=\"newsListingEntry\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <span class=\"newsListingSpace\">\n        <span class=\"row\">\n            <span class=\"col-xs-4 col-md-3\">\n                <span href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"newsListingImageWrap\">\n                    <span class=\"listingImage\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></span>\n                </span>\n            </span>\n            <span class=\"col-xs-8 col-md-9\">\n                <span class=\"newsListingContent\">\n                    <h4 class=\"newsListingTitle hidden-xs\"><a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h4>\n                    <h4 class=\"newsListingTitle visible-xs\"><a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4((helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),26,{"name":"truncate","hash":{},"data":data}))
    + "</a></h4>\n                    <span class=\"newsListingDateDescriptionContainer\">\n                        <span class=\"newsListingDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                        <span class=\"newsListingDescription hidden-xs\">"
    + ((stack1 = (helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),266,{"name":"truncate","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"newsListingDescription visible-xs\">"
    + ((stack1 = (helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),78,{"name":"truncate","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"newsListingLearnMore\">Read More</a>\n                    </span>\n                </span>\n            </span>\n        </span>\n    </span>\n</span>";
},"useData":true}));

Handlebars.registerPartial("newspromobox", this["JST"]["newspromobox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsGridPromoBox\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n<div class=\"newsBoxInfo\">\n	<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span></span><span class=\"newsDate\">"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n	<h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n	<h5>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h5>\n</div>";
},"useData":true}));

Handlebars.registerPartial("newspromocarousel", this["JST"]["newspromocarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <article class=\"item newsPromoCarouselItem"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <div class=\"container newsSliderWrap\">\n                    <div class=\"row\">\n                        <div class=\"col-md-12\">\n                            <div class=\"promoCarouselSpace\">\n                                <div class=\"promoCarouselDetails\">\n                                    <h3 class=\"promoCarouselTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                                    <p class=\"promoCarouselDescription\">"
    + alias4((helpers.truncate || (depth0 && depth0.truncate) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),114,{"name":"truncate","hash":{},"data":data}))
    + "</p>\n                                    <span class=\"promoCarouselMore\"><a href=\"#news/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">Read More</a></span>\n                                </div>\n                                <div class=\"promoCarouselImage\" style=\"background-image: url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ")\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </article>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                            <li class=\"promoCarouselDotsLink"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(data && data.index),"==",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-target=\"#newsPromoCarousel\" data-slide-to=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\"></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"newsPromoCarousel\" class=\"carousel slide newsPromoCarousel\" data-ride=\"carousel\">\n\n    <div class=\"carousel-inner newsPromoCarouselInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"container newsSliderWrap\">\n        <div class=\"row\">\n            <div class=\"col-md-12\">\n                <div class=\"newsPromoCarouselNavigationContainer\">\n                    <a class=\"left carousel-control newsPromoCarouselArrow\" href=\"#newsPromoCarousel\" data-slide=\"prev\"></a>\n                    <a class=\"right carousel-control newsPromoCarouselArrow\" href=\"#newsPromoCarousel\" data-slide=\"next\"></a>\n                    <ol class=\"carousel-indicators newsPromoCarouselDots\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promoNewsItems : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </ol>\n                </div>\n            </div>\n        </div>\n    </div>\n\n\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\" data-bgimage=\"scoring-body\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"visible-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"questionText\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"helpQuestion\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("sidebarbottommenu", this["JST"]["sidebarbottommenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-inner-list inner-closed\">\n	<span class=\"sidebar-inner-back\">\n		<div class=\"sidebar-icon pe-7s-left-arrow\"></div>\n		<div class=\"sidebar-label\">Back</div>\n	</span>\n	<ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebaritem", this["JST"]["sidebaritem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return " sidebarItemSelected";
},"7":function(container,depth0,helpers,partials,data) {
    return " sidebarItemNestedParent";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "sidebar-neverwrap";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <div class=\"sidebar-item-data data-"
    + container.escapeExpression(((helper = (helper = helpers.dataName || (depth0 != null ? depth0.dataName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dataName","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebartopmenu,depth0,{"name":"sidebartopmenu","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"sidebarItem"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.newtab : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n    <div class=\"sidebarItemLabel "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebartopmenu", this["JST"]["sidebartopmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <div class=\"sidebarItemNestedLabel\">\n        "
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n      </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\" class=\"sidebarItem sidebarItemNested\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebarItemNestedContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("winnerslistingentry", this["JST"]["winnerslistingentry"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"winnersListingEntry\" data-category=\""
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "\" data-winners=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"winnersListingEntrySpace\">\n        <a href=\"#finalists/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"winnersListingEntryMore\">\n            <figure class=\"winnersListingEntryImage\"><img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\"/></figure>\n            <div class=\"winnersListingEntryContent\">\n                <h4 class=\"winnersListingEntryTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n                <div class=\"winnersListingEntryDescription\">Team: "
    + ((stack1 = ((helper = (helper = helpers.team || (depth0 != null ? depth0.team : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"team","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n            </div>\n        </a>\n        <a href=\"#finalists/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"winnersListingEntryWebsite\">Learn More</a>\n    </div>\n</section>";
},"useData":true}));

this["JST"]["faqcards"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <div class=\"col-sm-12 col-md-4 qaCard\">\n            <div class=\"qaCardInner\" index=\""
    + container.escapeExpression(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\">\n                <h3 class=\"title\">"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n                <div class=\"backgroundElement\"></div>\n                <div class=\"hidden innerSwap\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-wrap expanderGrid\">\n  <div class=\"container\">\n  \n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  \n  \n  \n  <div class=\"row detailRow\">\n    <div class=\"expanderContent\"></div>\n    <div class=\"expanderNav\">\n      <span class=\"faqNavPrev materialIcons\">keyboard_arrow_left</span>\n      <span class=\"counter\">\n        <span class=\"index\"></span>\n        <span class=\"txt\">of</span>\n        <span class=\"total\"></span>\n      </span>\n      <span class=\"faqNavNext materialIcons\">keyboard_arrow_right</span>\n      <span class=\"faqNavClose materialIcons\">close</span>\n    </div>\n  </div>\n  </div>\n</div>";
},"useData":true});

this["JST"]["faqsaccordion"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <div class=\"faqPanel panel panel-default home-panel\">\n\n            <a class=\"faqAnchor collapsed\">\n                <div id=\"heading"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"faqHeading\" role=\"tab\">\n                    <h3 class=\"faqQuestion\">"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n                </div>\n                <div class=\"faqIcon\">\n                    <i class=\"materialIcons\">add</i>\n                    <i class=\"materialIcons iconRemove\">remove</i>\n                </div>\n            </a>\n\n            <div class=\"faqPanelCollapse panel-collapse collapse\">\n                <div class=\"faqBody\">\n                    "
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n\n        </div>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"faqAccordion\" class=\"faqPanelGroup panel-group\" role=\"tablist\" aria-multiselectable=\"false\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});

this["JST"]["footer"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"container\">\n    <div class=\"footerContent\">\n        <div class=\"footerLegalLinks\">\n            <a href=\"#terms-conditions\" class=\"legalLink\">Terms &amp; Conditions</a>\n            <a href=\"#privacy-policy\" class=\"legalLink\">Privacy Policy</a>\n            <a href=\"mailto:questions@chicagoprize.org\" class=\"legalLink\">Contact</a>\n\n        </div>\n        <div class=\"footerCopyright\">\n            <p class=\"footerCopyrightText\">Challenge designed by <a href=\"https://www.thecommonpool.org/\" target=\"_blank\" class=\"copyrightLink\">Common Pool</a>; <br>powered by <a href=\"https://www.rampit.com/\" target=\"_blank\" class=\"copyrightLink\">Rampit</a>. &copy; 2019 Rampit.</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfogroup,depth0,{"name":"judgeinfogroup","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"judges\" class=\"container card-container judgesCardContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<!--\n********************************\nJudge detail animation classes; can combine\njust adjust $judgesDetail-transition var\n\njudgeDetailSlideInRight\njudgeDetailFadeIn\n\n********************************\n-->\n\n<div id=\"judgesDetail\" class=\"judgesDetailContainer judgeDetailSlideInRight\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </p>\n                    <a href=\"#rp\" class=\"btnPrimary btnPrimaryAlternate\">Return to Dashboard</a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            Only <span class=\"daysLeft\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitSubTimeLeft : stack1), depth0))
    + "</span> <span class=\"dayLeftCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitSubTimeFrame : stack1), depth0))
    + "</span> to submit\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                            Submissions have closed\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                        </p>\n                        <a href=\"#login\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Login</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                Only <span class=\"daysLeft\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitSubTimeLeft : stack1), depth0))
    + "</span> <span class=\"dayLeftCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitSubTimeFrame : stack1), depth0))
    + "</span> to submit\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                                Submissions have closed.\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <p class=\"actionTitle\">Only <span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span> <span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to Register</p>\n                            <a href=\"#register\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Register</a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            <p class=\"actionTitle\">\n"
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitSubTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </p>\n                            <a href=\"#about\" class=\"btnPrimary btnPrimaryAlternate\" type=\"submit\">Learn More</a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                                    Registration has closed.\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                                    Submissions have closed.\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-sm-12 lastCardContent\">\n            <div class=\"lastCardContentArea\">\n                <div class=\"retired-load_all_last_card\">\n                    <h2>Chicago Prize is an initiative of Pritzker Traubert Foundation.</h2>\n                    <p>The Pritzker Traubert Foundation knows that addressing systemic poverty, economic exclusion, and inequity is complex and challenging. We are excited to partner with community leaders and combine our resources—passion, experience, and capital—to help enable more individuals and communities in Chicago to thrive.</p>\n                    <p>Thank you to Urban Institute and the many local and national experts who helped us develop this challenge, as well as to the MacArthur Foundation, Lever for Change, and BCG's Center for Illinois' Future for offering pro bono and other financial support to ensure a strong process.</p>\n                </div>\n                <div class=\"lastCardLogos\">\n                    <a href=\"https://www.ptfound.org/\" target=\"_blank\">\n                        <img src=\"/img/logos/ptf.svg\" width=\"143\" height=\"143\" alt=\"Pritzker Traubert Foundation\"/>\n                    </a>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-sm-12 lastCardAction\">\n            <div class=\"lastCardActionArea\">\n\n                <div class=\"actionLoadCard\">\n                    <h3>Let’s come together, dream big, and act boldly to build a stronger Chicago and enable residents to create a more vibrant, promising future for their neighborhoods.</h3>\n                </div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n    <div class=\"lastCardSocialLinks\">\n        <a href=\"https://www.linkedin.com/company/ptfound/about/\" class=\"lastCardSocialLink\" target=\"_blank\">\n            <svg class=\"networkIcon\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 50 50\" width=\"14px\" height=\"14px\">\n                <path d=\"M 8 3.0097656 C 4.53 3.0097656 2.0097656 5.0892187 2.0097656 7.9492188 C 2.0097656 10.819219 4.59 12.990234 8 12.990234 C 11.47 12.990234 13.990234 10.870625 13.990234 7.890625 C 13.830234 5.020625 11.36 3.0097656 8 3.0097656 z M 3 15 C 2.45 15 2 15.45 2 16 L 2 45 C 2 45.55 2.45 46 3 46 L 13 46 C 13.55 46 14 45.55 14 45 L 14 16 C 14 15.45 13.55 15 13 15 L 3 15 z M 18 15 C 17.45 15 17 15.45 17 16 L 17 45 C 17 45.55 17.45 46 18 46 L 27 46 C 27.552 46 28 45.552 28 45 L 28 30 L 28 29.75 L 28 29.5 C 28 27.13 29.820625 25.199531 32.140625 25.019531 C 32.260625 24.999531 32.38 25 32.5 25 C 32.62 25 32.739375 24.999531 32.859375 25.019531 C 35.179375 25.199531 37 27.13 37 29.5 L 37 45 C 37 45.552 37.448 46 38 46 L 47 46 C 47.55 46 48 45.55 48 45 L 48 28 C 48 21.53 44.529063 15 36.789062 15 C 33.269062 15 30.61 16.360234 29 17.490234 L 29 16 C 29 15.45 28.55 15 28 15 L 18 15 z\"\n                    fill=\"#fff\"/>\n            </svg>\n        </a>\n    </div>\n</div>";
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"navbarBtnWrap\">\n                    <a href=\"#rp\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Return to Dashboard</a>\n                </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"navbarBtnWrap\">\n                        <a href=\"#login\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Login</a>\n                    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.rampitRegTimeLeft : stack1),">",0,{"name":"ifComp","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <span class=\"navbarBtnWrap\">\n                            <a href=\"#register\" class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\"><span class=\"dayCount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeLeft : stack1), depth0))
    + "</span>&nbsp;<span class=\"dayCountWord\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.timeConfig : depth0)) != null ? stack1.rampitRegTimeFrame : stack1), depth0))
    + "</span> to Register</a>\n                        </span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"navbarBtnWrap\">\n                            <a class=\"navbarBtn btnPrimary btnPrimaryAlternate solid navreg retire-modal-trigger\">Learn More</a>\n                        </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav class=\"navbar\" role=\"navigation\">\n    <div class=\"container\">\n\n        <div class=\"navbarHeader layout-vertical-center\">\n            <a class=\"navbarBrand anim\" href=\"/#home\"></a>\n        </div>\n\n        <div class=\"navbarMenuWrap\">\n            <div class=\"navbarMenuBtn jsMenuControl\">\n                <span class=\"menuLabel hamburger\">\n                    <span class=\"hamburgerTop\"></span>\n                    <span class=\"hamburgerMiddle\"></span>\n                    <span class=\"hamburgerBottom\"></span>\n                </span>\n            </div>\n\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <div class=\"navbarMenuSocial\">\n                <span class=\"navbarMenuSocialTitle\">Share</span>\n                <div class=\"navbarMenuSocialList\">\n                    <a class=\"socialLink\" target=\"_blank\" data-action=\"facebook\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                        <img src=\"img/social/facebook_icon.png\" alt=\"Facebook Social Share Icon\">\n                    </a>\n                    <a class=\"socialLink\" target=\"_blank\" data-action=\"twitter\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                        <img src=\"img/social/twitter_icon.png\" alt=\"Twitter Social Share Icon\">\n                    </a>\n                    <a class=\"socialLink\" target=\"_blank\" data-action=\"linkedin\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                        <img src=\"img/social/linkedin_icon.png\" alt=\"Linked In Social Share Icon\">\n                    </a>\n                </div>\n            </div>\n        </div>\n\n    </div>\n</nav>";
},"useData":true});

this["JST"]["newsDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"newsModalBtnNav navPrev\">\n                            <span class=\"newsModalBtnNavInner icon-icon-arrow-left-block\"></span>\n                        </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <a href=\"#news/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"newsModalBtnNav navNext\">\n                            <span class=\"newsModalBtnNavInner icon-icon-arrow-right-block\"></span>\n                        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "<div class=\"newsModal\">\n\n\n\n    <div class=\"newsModalCard\">\n\n        <div class=\"innerScrollable\">\n\n\n            <div class=\"newsModalContentContainer\">\n                <div class=\"newsModalNav\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <div class=\"newsModalCloseWrap\">\n                        <div class=\"newsModalCloseBtn modalClose\">\n                            <span class=\"newsModalCloseBtnInner icon-icon-close\"></span>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"newsModalContent\">\n                    <div class=\"newsImageWrap\">\n                        <div class=\"newsImage\" style=\"background-image:url('"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1), depth0))
    + "')\"></div>\n                    </div>\n                    <h4 class=\"newsItemTitle\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                    <div class=\"content\">\n                        "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n</div>";
},"useData":true});

this["JST"]["newspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newslistingentry,depth0,{"name":"newslistingentry","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.newspromocarousel,depth0,{"name":"newspromocarousel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"container newsListingWrap\">\n\n    <div class=\"newsListing\">\n\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<div class=\"newsDetailContainer\"></div>";
},"usePartial":true,"useData":true});

this["JST"]["newspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"newsinfoWrap border-box\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row news-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"newsImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["portraitmask"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"portraitmask layout-vertical-center\">\n    <div class=\"portraitmaskBrand\"></div>\n    <div style=\"width:100%\">\n        <div class=\"portraitmaskImage\"></div>\n        <div class=\"portraitmaskContent\">\n            <p>This website was designed to be experienced in <span class=\"visible-landscape-mode\">portrait&nbsp;</span><span class=\"visible-portrait-mode\">landscape</span> mode. We apologize for any inconvenience this may cause!</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["processstepdetail"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"processCardDetailButton btn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div class=\"cardContent\" data-processid=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <span class=\"processCardDetailDate\">"
    + ((stack1 = ((helper = (helper = helpers.dates || (depth0 != null ? depth0.dates : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dates","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n    <h3 class=\"processCardDetailTitle\">"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n    <div class=\"processCardDetailContent\">\n        "
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["JST"]["processsteps"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <section class=\"processCard\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                <div class=\"processCardSpace\">\n                    <span class=\"processCardNumber\">Step "
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</span>\n                    <h3 class=\"processCardTitle\">"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n                    <time class=\"processCardDate\">"
    + ((stack1 = ((helper = (helper = helpers.dates || (depth0 != null ? depth0.dates : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dates","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</time>\n                </div>\n            </section>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"processContainer\">\n    <div class=\"processCardsContainer\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"processCardDetailsContainer\">\n        <div class=\"processCardDetails\"></div>\n        <div class=\"processCardDetailControls\">\n            <span class=\"cardDetailControl cardDetailControlPrev\"></span>\n            <span class=\"counts\">Step&nbsp;<span class=\"current\"></span><span class=\"separator\">/</span><span class=\"total\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.steps : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span></span>\n            <span class=\"cardDetailControl cardDetailControlNext\"></span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"sliderTick\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"scoreEntry\">\n    <h3 class=\"scoreTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n    <div class=\"scoreHeading\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"scoreDescription\">\n        <span class=\"scoreDescriptionInner\">\n            <span class=\"scoreDescriptionValueNumber\"> <span class=\"scoreDescriptionValueNumberInner\"></span> points.</span>\n            <span class=\"scoreDescriptionText\"></span>\n        </span>\n    </div>\n    <div class=\"scoreValue\">\n        <div class=\"scoreValueSpace\">\n            <span class=\"valueNumber\" status=\""
    + alias4(((helper = (helper = helpers.statusScore || (depth0 != null ? depth0.statusScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"statusScore","hash":{},"data":data}) : helper)))
    + "\"></span>\n            <span class=\"valueBorder valueBorderLeft\"></span>\n            <span class=\"valueBorder valueBorderRight\"></span>\n        </div>\n    </div>\n    <div class=\"scoreSliderWrap\">\n        <div class=\"scoreSlider\">\n            <input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n        </div>\n        <div class=\"sliderTicks\">\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"sliderAnchor sliderAnchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"sliderAnchor sliderAnchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n</section>\n";
},"useData":true,"useDepths":true});

this["JST"]["sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebaritem,depth0,{"name":"sidebaritem","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "                <a href=\"/logout.aspx\" class=\"logoutBtn retire-modal-trigger\">I want to Logout<i class=\"icon-arrow-right\"></i></a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "                    <a href=\"#login\" class=\"retire-modal-trigger loginBtn\">I want to Login<i class=\"icon-arrow-right\"></i></a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <a href=\"#register\" class=\"retire-modal-trigger registerBtn\">I want to Register<i class=\"icon-arrow-right\"></i></a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"sidebarInnerWrap\">\n    <div class=\"closeWrap closeButton\"></div>\n\n    <div class=\"sidebarItemContainer\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.menuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div id=\"sideMenu\">\n        <div class=\"mobile-login\">\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["sponsorCarousel"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"carousel-item item "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n          <h2 class=\"sponsorName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h2>\n          <p class=\"sponsorText\">"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</p>\n          <button class=\"btnPrimary btnPrimaryAlternate\">Action</button>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"carousel slide\" data-ride=\"carousel\">\n  <div class=\"sponsorWrap carousel-inner\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sponsor : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <div class=\"btnNavWrap\">\n    <a class=\"btnNav prev materialIcons\" data-slide=\"prev\" data-target=\".sponsorCarousel .carousel\">keyboard_arrow_left</a>\n    <a class=\"btnNav next materialIcons\" data-slide=\"next\" data-target=\".sponsorCarousel .carousel\">keyboard_arrow_right</a>\n  </div>\n</div>\n";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"timelinePointWrapper "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-periodname=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"top:"
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\">\n                    <div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-destination-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></div>\n                    <div class=\"timelineConnectLine "
    + ((stack1 = (helpers.ifComp || (depth0 && depth0.ifComp) || alias2).call(alias1,(depth0 != null ? depth0.sizePx : depth0),"<=",0,{"name":"ifComp","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"height: "
    + alias4(((helper = (helper = helpers.sizePx || (depth0 != null ? depth0.sizePx : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePx","hash":{},"data":data}) : helper)))
    + "px\"></div>\n                    <div class=\"timelineBoxWrapper\">\n                        <div class=\"timelineBox\">\n                            <span class=\"timelineBoxArrow\"></span>\n                            <h3 class=\"timelineBoxTitle\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                            <h6 class=\"timelineBoxSubtitle\">"
    + alias4(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</h6>\n                            <div class=\"timelineBoxDetails\">\n                                "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "first";
},"4":function(container,depth0,helpers,partials,data) {
    return "last";
},"6":function(container,depth0,helpers,partials,data) {
    return "selected";
},"8":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "                                    <div class=\"timelineBoxBtnWrap\">\n                                        <a class=\"timelineBoxBtn btnPrimary btnPrimaryAlternate\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n                                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"timelinePeriod\" style=\"top: "
    + alias4(((helper = (helper = helpers.offsetPercentage || (depth0 != null ? depth0.offsetPercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"offsetPercentage","hash":{},"data":data}) : helper)))
    + "%; height:"
    + alias4(((helper = (helper = helpers.sizePercentage || (depth0 != null ? depth0.sizePercentage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sizePercentage","hash":{},"data":data}) : helper)))
    + "%\" data-periodname=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n                <div class=\"timelinePeriodDetailsWrap container\">\n                    <div class=\"timelinePeriodDetails\">\n                        <h3 class=\"timelinePeriodName\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n                        <h5 class=\"timelinePeriodLength\">"
    + alias4(((helper = (helper = helpers.periodText || (depth0 != null ? depth0.periodText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodText","hash":{},"data":data}) : helper)))
    + "</h5>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"timelineContainer\">\n\n    <div class=\"timelineContainerInner\">\n\n        <div class=\"timelinePointsOuterWrapper container\">\n            <div class=\"timelineCurrentMoment\" style=\"top:"
    + container.escapeExpression(((helper = (helper = helpers.currentMommentOffsetPercentage || (depth0 != null ? depth0.currentMommentOffsetPercentage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentMommentOffsetPercentage","hash":{},"data":data}) : helper)))
    + "%;\"></div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePeriods : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n        <span class=\"timelineNavigation timelineNavigation--hidden\">\n          <span class=\"timelineNavigationBtn prev\">\n            <span class=\"timelineNavigationBtnInner\"></span>\n          </span>\n          <span class=\"timelineNavigationBtn next\">\n            <span class=\"timelineNavigationBtnInner\"></span>\n          </span>\n        </span>\n\n    </div>\n\n</div>";
},"useData":true});

this["JST"]["videoOverlay"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"videoOverlay\">\n	<span class=\"btnNav closeVideoOverlay materialIcons\">close</span>\n	<iframe class=\"video\" src=\"https://www.youtube.com/embed/LDLQfFsSg5E?enablejsapi=1&version=3&playerapiid=ytplayer\" frameborder=\"0\" allowfullscreen></iframe>\n</div>\n";
},"useData":true});

this["JST"]["winnersDetailsModal"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <div class=\"imageWrapLargeInner\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1), depth0))
    + ");\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1), depth0))
    + "\" class=\"winners-item-website\" target=\"_blank\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.linktext : stack1), depth0))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"modal-winners\">\n    <div class=\"cardWrapper\">\n\n        <div class=\"cardWrap\">\n            <div class=\"inner-scrollable\">\n                <div class=\"modal-nav-wrap\">\n                    <div class=\"modal-nav-controls-wrap\">\n                        <a href=\"#finalists/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.prevItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"prev\"></a>\n                        <a href=\"#finalists/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.nextItem : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"next\"></a>\n                        <div class=\"modal-close-wrap\"><span class=\"modal-close\"></span></div>\n                    </div>\n                </div>\n                <section class=\"detail\">\n                    <div class=\"modal-content-container\">\n                        <div class=\"content-container\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.image : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            <h4 class=\"winners-item-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                            <p class=\"winners-item-team\">Team: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.team : stack1), depth0))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.link : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            <div class=\"content\">\n                                "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.item : depth0)) != null ? stack1.content : stack1), depth0)) != null ? stack1 : "")
    + "\n                            </div>\n                            <div class=\"social\">\n                                <span class=\"social-title\">Share</span>\n                                <div class=\"social-list\">\n                                    <a class=\"socialLink\" target=\"_blank\" data-action=\"facebook\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                                        <img src=\"img/social/facebook_icon-blue.png\" alt=\"Facebook\">\n                                    </a>\n                                    <a class=\"socialLink\" target=\"_blank\" data-action=\"twitter\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                                        <img src=\"img/social/twitter_icon-blue.png\" alt=\"Twitter\">\n                                    </a>\n                                    <a class=\"socialLink\" target=\"_blank\" data-action=\"linkedin\" data-title=\"Chicago Prize awards $10 million from the Pritzker Traubert Foundation to support community efforts to build a stronger, more vibrant future for Chicago’s South and/or West Side.\">\n                                        <img src=\"img/social/linkedin_icon-blue.png\" alt=\"LinkedIn\">\n                                    </a>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </section>\n            </div>\n        </div>\n\n    </div>\n</div>";
},"useData":true});

this["JST"]["winnerspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.winnerslistingentry,depth0,{"name":"winnerslistingentry","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"winnersSection\">\n    <div class=\"retired-load_finalists_card1 retired-layout-load-card\">\n        <h3 class=\"winnersTitle\">Finalists</h3>\n        <p class=\"winnersText\">On December 11, 2019, Pritzker Traubert Foundation announced six Chicago Prize Finalists. Each Finalist will be supported by a $100,000 planning grant and in-kind technical assistance to support efforts to refine their plans. In spring/summer 2020, one $10 million grant will be awarded to a single community-driven initiative that is able to leverage the grant, catalyze economic opportunity, and improve the well-being of residents.</p>\n    </div>\n    <div class=\"winnersListing\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.finalists : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div class=\"winnersSection winnersSectionPromising\">\n    <div class=\"retired-load_finalists_card2 retired-layout-load-card\">\n        <h3 class=\"winnersTitle\">Top-Ranked Proposals</h3>\n    </div>\n    <div class=\"winnersListing\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.promising : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<section class=\"winnersExplore\">\n    <div class=\"retired-load_finalists_card3 retired-layout-load-card\">\n        <h3>Investing in Our Neighborhoods</h3>\n        <p>The strength of Chicago neighborhoods is reflected in the Chicago Prize process. Please read the <a href=\"https://drive.google.com/file/d/1XHpqHQTtsClVMSLAJFUwDrfo-K2_T6hp/view\" target=\"_blank\">Chicago Prize booklet</a> to learn more about the more than 80 organizations who submitted thoughtful, community-led ideas to benefit their neighborhoods across the South and West Sides of Chicago.</p>\n    </div>\n</section>\n\n\n<div class=\"winnersDetailContainer\"></div>";
},"usePartial":true,"useData":true});

this["JST"]["winnerspost"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"winnersinfoWrap border-box\" data-winners=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n    <div class=\"row winners-detail\">\n        <div class=\"col-md-5\">\n            <div class=\"winnersImageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + ");\"></div>\n        </div>\n        <div class=\"col-md-6 inner-scrollable\">\n            <span>"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n            <h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n            <h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-sm-12 col-md-offset-1 col-md-10 text-center\">\n                <div class=\"load_404_card1 layout-load-card\">\n                    <h1>404 error</h1>\n                    <p>Sorry, we can’t find the page you were looking for.</p>\n                </div>\n                <p>Here are some helpful links that might help:</p>\n                <p><a href=\"#home/\">Home</a></p>\n                <p><a href=\"#news\">News & Updates</a></p>\n                <p><a href=\"#about\">About</a></p>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
