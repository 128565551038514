define('hooks',['jquery', 'app/util'], function ($, util) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");

            $('.js-scroll-btn').on('click', function () {
                var $self = $(this),
                    elementToScrollTo = $self.attr('data-scroll-to');

                $('html, body').animate({
                    scrollTop: $('.' + elementToScrollTo).offset().top
                }, 1000);
            });

            $('.aboutPage .about').on('click', '.link-placeholder', function (event) {
                event.preventDefault();
                var $self = $(this),
                    href = $self.attr('href');

                history.pushState(null, 'About', href);

                $('html, body').animate({
                    scrollTop: $self.offset().top
                }, 1000);
            });

            $('.missingPageWrap').on('touchstart', 'a', function () {
                $(this).trigger('click');
            });

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {
                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }

            $('.finalistsAnnouncement__close').on('click', function () {
                $(this).closest('.finalistsAnnouncement').addClass('finalistsAnnouncement--collapse');
            });

            $('body').on('click', '.retire-modal-trigger', function (e) {
                e.preventDefault();
                $('.retire-modal').addClass('visible');
                $('body').addClass('modal-visible');
            });

            $('.close-retire-modal').on('click', function () {
                $('.retire-modal').removeClass('visible');
                $('body').removeClass('modal-visible');
            });
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

