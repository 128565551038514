define('modules/winners/basic/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "jquery.mobile.custom"], function (module, config, $, util, templates) {
    return new module({
        name: 'finalists',
        selectors: '.finalistsPage',
        remoteData: [config.urls.finalists],

        routes: [{
            route: 'finalists/(\\d+)',
            fn: function (hash) {
                var winnersId = hash.split('/')[1];
                this.renderWinnersDetails(winnersId);
            },
            extra: true,
            delay: 300
        }, {
            route: 'finalists',
            fn: 'closeDetails',
            extra: true
        }],

        config: {},

        initialize: function (data) {
            this.$container = $(this.getSelectors());
            this.data = data;
            var finalists = [], promising = [];

            for (var i = 0; i < data.length; i++) {
                if (data[i].finalist) {
                    finalists.push(data[i]);
                } else {
                    promising.push(data[i]);
                }
            }

            this.$container.find('.winnersContainer').html(templates.winnerspage({
                finalists: finalists,
                promising: promising
            }));

            this.flexCheck();
            this.attachEventHandlers();
        },

        onRender: function () {
            console.log('onRender');
            if (this['defferedRenderWinnersDetail']) {
                this.renderWinnersDetails(this['defferedRenderWinnersDetail']);
            }
        },

        methods: {

            renderWinnersDetails: function (winnersId) {
                if (!this.isReady()) {
                    this.defferedRenderWinnersDetail = winnersId;
                    return;
                }

                if (!this.winnersById) {
                    var winnersById = {};
                    $.each(this.data, $.proxy(function (index, item) {
                        item.originalIndex = index;
                        winnersById[item.id] = item;
                    }, this));
                    this.winnersById = winnersById;
                }
                var winnersItem = this.winnersById[winnersId];

                var finalists = [], promising = [], i, a = 0, b = 0;
                for (i = 0; i < this.data.length; i++) {
                    if (this.data[i].finalist) {
                        this.data[i].newIndex = a;
                        finalists.push(this.data[i]);
                        a++;
                    } else {
                        this.data[i].newIndex = b;
                        promising.push(this.data[i]);
                        b++;
                    }
                }
                //console.log('finalists', finalists);
                //console.log('promising', promising);

                var dataArray = this.data;
                if (finalists.length > 0) {
                    $.each(finalists, $.proxy(function (index, item) {
                        if (item.id === +winnersId) {
                            dataArray = finalists;
                        }
                    }, this));
                }

                if (promising.length > 0) {
                    $.each(promising, $.proxy(function (index, item) {
                        if (item.id === +winnersId) {
                            dataArray = promising;
                        }
                    }, this));
                }

                //
                // console.log(dataArray);

                if (winnersItem) {
                    var i, currentItem;
                    if (!winnersItem.prevItem) {
                        if ((winnersItem.newIndex - 1) >= 0) {
                            currentItem = dataArray[winnersItem.newIndex - 1];
                        } else {
                            currentItem = dataArray[dataArray.length - 1];
                        }
                        winnersItem.prevItem = currentItem;
                    }
                    if (!winnersItem.nextItem) {
                        if ((winnersItem.newIndex + 1) < dataArray.length) {
                            currentItem = dataArray[winnersItem.newIndex + 1];
                        } else {
                            currentItem = dataArray[0];
                        }
                        winnersItem.nextItem = currentItem;
                    }
                    winnersItem.len = dataArray.length;
                    winnersItem.len = ('0' + winnersItem.len).slice(-2);
                    winnersItem.index = winnersItem.originalIndex + 1;
                    winnersItem.index = ('0' + winnersItem.index).slice(-2);

                    //
                    // console.log(winnersItem);

                    $('body').addClass('finalistsModalOpen').find('.navbarWrap').addClass('hidden').hide();

                    this.$container.find('.winnersDetailContainer').html(templates.winnersDetailsModal({
                        item: winnersItem
                    }));

                    this.$container.find('.carousel.slide').carousel('pause');

                    setTimeout($.proxy(function () {
                        this.$container.find('.winnersDetailContainer').addClass('winners-details-opened');
                    }, this), 50);
                }
            },

            closeDetails: function () {
                if (this.isReady()) {
                    var winnersDetailContainer = $('.winnersDetailContainer');
                    $('body').removeClass('finalistsModalOpen');
                    winnersDetailContainer.removeClass('winners-details-opened');
                    setTimeout($.proxy(function () {
                        this.$container.find('.winnersDetailContainer').empty();
                    }, this), 1000);
                    $('.navbarWrap').removeClass('hidden').show();
                    this.$container.find('.carousel.slide').carousel('cycle');
                }
            },

            attachEventHandlers: function () {
                this.$container.on('click', '.winnersDetailContainer .modal-close', $.proxy(function (e) {
                    e.preventDefault();
                    window.history.pushState('finalists', 'Finalists', '#finalists');
                    this.closeDetails();
                }, this));

                this.$container.on('click', '.winnersDetailContainer .modal-nav-controls-wrap a', $.proxy(function (e) {
                    $(this).blur();
                }, this));

                this.$container.on('click', '.js-youtube', function (e) {
                    e.preventDefault();
                    $(this).closest('.winnersListingEntryRow').find('.winnersListingEntryPlayer').addClass('open');
                });

                this.$container.on('click', '.js-youtube-close', function (e) {
                    e.preventDefault();
                    var container = $(this).closest('.winnersListingEntryPlayer'),
                        player = $('.winners-detail-video', container);
                    var leg = player.attr('src');
                    //console.log(leg);
                    player.attr('src', '');
                    player.attr('src', leg);
                    container.removeClass('open');
                });
            },

            flexCheck: function () {
                if ($('.winnersGridFlex .winnersGridPromoBox').length === 2) {
                    $('.winnersGridFlex').addClass('flex-two');
                }
            }
        }
    });
});

