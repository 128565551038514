// require all needed dependencies here
define('modules/videoOverlay/basic/js/base.js',["app/config","jquery","app/module","app/util","templates"], function(config,$,module,util,templates) {
    return new module({
        name: 'videoOverlay',
        selectors: '.videoOverlayWrap',
        remoteData: [],

        initialize: function (data) {
            // the selector will be always present in the DOM, please use the cached version whenever possible
            this.$container = $(this.getSelectors());
            this.data = data;
        
            this.renderTemplates(data);
            this.attachEventHandlers();
            this.setInitialState();
        },

        // once the executon of initialize has ended this method will be called
        onRender: function(){

        },

        methods: {
            renderTemplates: function(data){
                this.$container.append(templates.videoOverlay());
            },

            attachEventHandlers: function(data){

                $('.videoPlayButton').on('click', function() {
                    $('body').addClass('locked');
                    $('.videoOverlayWrap').addClass('shown');
                });
    
                $('.closeVideoOverlay').on('click', function() {
                    $('body').removeClass('locked');
                    $('.videoOverlayWrap').removeClass('shown');
                    $('.videoOverlayWrap .video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                });

                this.$container.on('click', $.proxy(function(event){
                    // using jQuery's proxy function we will have access to the module using "this" keyword
                }, this));
            },

            setInitialState: function(data){

            }
        }

    });
});
